var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tw-flex tw-w-full tw-flex-col lg:"},[_c('div',{staticClass:"tw-cursor-pointer",on:{"click":_vm.routeBack}},[_c('icons',{attrs:{"name":"arrow-back"}})],1),_c('div',{staticClass:"tw-w-full tw-flex tw-justify-center lg:tw-mt-10 tw-mt-5 tw-p-5"},[_c('div',{staticClass:"company-dashboard tw-p-5"},[_c('h6',{staticClass:"header"},[_vm._v("Approve Pos Request")]),_c('div',{staticClass:"tw-flex tw-w-full tw-flex-col tw-mt-10"},[_c('h6',{staticClass:"request-header tw-flex tw-items-center text-capitalize"},[_c('span',{staticClass:"tw-w-4/12"},[_vm._v("Requesting Company :")]),_vm._v(" "+_vm._s(_vm.posRequested.transportCompanyName))]),_c('h6',{staticClass:"request-header tw-flex tw-items-center tw-mt-5"},[_c('span',{staticClass:"tw-w-4/12 "},[_vm._v("Receiving Terminal : ")]),_vm._v(" "+_vm._s(_vm.posRequested.terminalName))]),(_vm.posRequested.requestedPos)?_c('h6',{staticClass:"request-header tw-flex tw-items-center tw-mt-5"},[_c('span',{staticClass:"tw-w-4/12 "},[_vm._v("Total Pos Requested : ")]),_vm._v(" "+_vm._s(_vm.totalPos))]):_vm._e(),(_vm.posRequested.requestedPos)?_c('h6',{staticClass:"request-header tw-flex tw-items-center tw-mt-5"},[_c('span',{staticClass:"tw-w-4/12 "},[_vm._v("Total Pos Approved : ")]),_vm._v(" "+_vm._s(_vm.posRequested.totalPosApproved))]):_vm._e(),_c('h6',{staticClass:"request-header tw-flex tw-items-center tw-mt-5"},[_c('span',{staticClass:"tw-w-4/12 "},[_vm._v("Date of Request : ")]),_vm._v(" "+_vm._s(_vm.formatDate(_vm.posRequested.createdAt)))]),_c('div',{staticClass:"tw-w-full tw-mt-10"},[_c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.approvePosRequest)}}},[_vm._l((_vm.posRequestData.requestedPos),function(request,index){return _c('div',{key:index,staticClass:"tw-flex tw-w-full tw-flex-col"},[(request.approvalStatus === 'null' || request.approvalStatus === 'PENDING')?_c('div',{staticClass:"tw-w-full"},[_c('div',{staticClass:"tw-flex tw-w-full tw-items-center tw-justify-between tw-my-5"},[_c('h6',{staticClass:"request-header tw-flex tw-items-center "},[_vm._v(" Fill Pos Details   "),(index > 0)?_c('span',[_vm._v(" ( "+_vm._s(index+1)+" )")]):_vm._e()]),_c('div',{staticClass:"tw-flex tw-flex-row tw-items-center"},[(index > 0)?_c('div',{staticClass:"tw-w-auto"},[_c('v-col',{staticClass:"tw-cursor-pointer",on:{"click":function($event){return _vm.removeFromApproval(index)}}},[_c('icons',{attrs:{"name":"confirm-delete","height":"20","width":"20","color":"#004AAD"}})],1)],1):_vm._e()])]),_c('v-row',{staticClass:"tw-flex tw-flex-col lg:tw-flex-row tw-w-full tw-items-center"},[_c('v-col',{staticClass:"tw-w-4/12"},[_c('h6',{staticClass:"request-header tw-flex tw-items-center"},[_vm._v(" Select POS Provider")])]),_c('v-col',{staticClass:"tw-w-8/12"},[_c('ValidationProvider',{attrs:{"name":"POS Provider","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('div',{staticClass:"tw-pt-4 mb-2",class:classes},[_c('v-combobox',{attrs:{"items":_vm.posProviders,"item-text":"companyName","item-value":"id","placeholder":"Irofit","solo":"","hide-details":"","type":"text","append-icon":"mdi-chevron-down"},model:{value:(request.posProviderId),callback:function ($$v) {_vm.$set(request, "posProviderId", $$v)},expression:"request.posProviderId"}}),_c('span',[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1)],1),_c('v-row',{staticClass:"tw-flex tw-flex-col lg:tw-flex-row tw-w-full tw-items-center"},[_c('v-col',{staticClass:"tw-w-4/12"},[_c('h6',{staticClass:"request-header tw-flex tw-items-center"},[_vm._v(" POS Name:")])]),_c('v-col',{staticClass:"tw-w-8/12"},[_c('ValidationProvider',{attrs:{"name":"POS Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('div',{staticClass:"tw-pt-4 mb-2",class:classes},[_c('v-text-field',{attrs:{"placeholder":"A75","solo":"","hide-details":"","type":"text","required":""},model:{value:(request.posName),callback:function ($$v) {_vm.$set(request, "posName", $$v)},expression:"request.posName"}}),_c('span',[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1)],1),_c('v-row',{staticClass:"tw-flex tw-flex-col lg:tw-flex-row tw-w-full tw-items-center"},[_c('v-col',{staticClass:"tw-w-4/12"},[_c('h6',{staticClass:"request-header tw-flex tw-items-center"},[_vm._v(" Manufacturer:")])]),_c('v-col',{staticClass:"tw-w-8/12"},[_c('ValidationProvider',{attrs:{"name":"Manufacturer","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('div',{staticClass:"tw-pt-4 mb-2",class:classes},[_c('v-text-field',{attrs:{"placeholder":"Aisino","solo":"","hide-details":"","type":"text","required":""},model:{value:(request.manufacturer),callback:function ($$v) {_vm.$set(request, "manufacturer", $$v)},expression:"request.manufacturer"}}),_c('span',[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1)],1),_c('v-row',{staticClass:"tw-flex tw-flex-col lg:tw-flex-row tw-w-full tw-items-center"},[_c('v-col',{staticClass:"tw-w-4/12"},[_c('h6',{staticClass:"request-header tw-flex tw-items-center"},[_vm._v(" Serial Number:")])]),_c('v-col',{staticClass:"tw-w-8/12"},[_c('ValidationProvider',{attrs:{"name":"Serial Number","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('div',{staticClass:"tw-pt-4 mb-2",class:classes},[_c('v-text-field',{attrs:{"placeholder":"E7653JH5Q","solo":"","hide-details":"","type":"text","required":""},model:{value:(request.serialNumber),callback:function ($$v) {_vm.$set(request, "serialNumber", $$v)},expression:"request.serialNumber"}}),_c('span',[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1)],1),_c('v-row',{staticClass:"tw-flex tw-flex-col lg:tw-flex-row tw-w-full tw-items-center"},[_c('v-col',{staticClass:"tw-w-4/12"},[_c('h6',{staticClass:"request-header tw-flex tw-items-center"},[_vm._v(" Pos Type:")])]),_c('v-col',{staticClass:"tw-w-8/12"},[_c('ValidationProvider',{attrs:{"name":"Serial Number","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('div',{staticClass:"tw-pt-4 mb-2",class:classes},[_c('v-combobox',{attrs:{"placeholder":"Android","solo":"","hide-details":"","items":_vm.posTypes,"type":"text","append-icon":"mdi-chevron-down","required":""},model:{value:(request.type),callback:function ($$v) {_vm.$set(request, "type", $$v)},expression:"request.type"}}),_c('span',[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1)],1),_c('v-row',{staticClass:"tw-flex tw-flex-col lg:tw-flex-row tw-w-full tw-items-center"},[_c('v-col',{staticClass:"tw-w-4/12"},[_c('h6',{staticClass:"request-header tw-flex tw-items-center"},[_vm._v(" Price:")])]),_c('v-col',{staticClass:"tw-w-8/12"},[_c('ValidationProvider',{attrs:{"name":"Unit Price","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('div',{staticClass:"tw-pt-4 mb-2",class:classes},[_c('v-text-field',{attrs:{"placeholder":"50000","solo":"","hide-details":"","type":"Number","required":""},model:{value:(request.unitPrice),callback:function ($$v) {_vm.$set(request, "unitPrice", $$v)},expression:"request.unitPrice"}}),_c('span',[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1)],1),_c('v-row',{staticClass:"tw-flex tw-flex-col lg:tw-flex-row tw-w-full tw-items-center"},[_c('v-col',{staticClass:"tw-w-4/12"},[_c('h6',{staticClass:"request-header tw-flex tw-items-center"},[_vm._v(" Management Fee:")])]),_c('v-col',{staticClass:"tw-w-8/12"},[_c('ValidationProvider',{attrs:{"name":"Management Fee","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('div',{staticClass:"tw-pt-4 mb-2",class:classes},[_c('v-text-field',{attrs:{"placeholder":"50000","solo":"","hide-details":"","type":"Number","required":""},model:{value:(request.managementFee),callback:function ($$v) {_vm.$set(request, "managementFee", $$v)},expression:"request.managementFee"}}),_c('span',[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1)],1)],1):_vm._e()])}),_c('div',{staticClass:"tw-flex tw-w-full tw-justify-around tw-items-center tw-mt-5 action-btn-div"},[_c('v-btn',{staticClass:"first-btn",style:({
                      color: '#004AAD',
                    border: '1px solid #004AAD' }),attrs:{"text":""},on:{"click":function($event){return _vm.$emit('close')}}},[_vm._v(" Cancel ")]),_c('v-btn',{staticClass:"second-btn",style:({ background: '#004AAD', color: 'white' }),attrs:{"text":"","type":"submit","loading":_vm.approveLoading}},[_vm._v(" Approve Request ")])],1)],2)]}}])})],1)])])])])}
var staticRenderFns = []

export { render, staticRenderFns }