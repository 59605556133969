<script>
import Icons from "@/components/reuseables/Icons.vue";
import {approvePosRequest, getAllPosProvider, getPosRequestById} from "@/services/api/APIService";
import dayjs from "dayjs";

export default {
  name: "ApprovePosView",
  components: {Icons},
  data() {
    return {
      posRequestData:{},
      posRequested:{},
      posTypes:["Android", "iOS","Native"],
      approveLoading: false,
      loading : false,
      totalPos: null,
      posProviders : []
    }
  },
  computed:{

  },
  methods:{
    routeBack(){
      this.$router.push({name: "PosDashboard"})
    },
    approvePosRequest(){
      this.approveLoading = true
      this.posRequestData.requestedPos = this.posRequestData.requestedPos.filter(request =>
          request.approvalStatus === "null" || request.approvalStatus === "PENDING")
      this.posRequestData.requestedPos.forEach(request => {
        if (request.posProviderId && Object.keys(request.posProviderId).length){
          request.posProviderId = request.posProviderId.id
        }})
      approvePosRequest(this.posRequestData).then(() =>{
        this.approveLoading = false
        this.$router.push({name: "PosDashboard"})
      }).catch(err =>{
        console.log(err.response.data)
        this.approveLoading = false
      }).finally(() => this.approveLoading = false)

    },
    resetPos(){

    },
    removeFromApproval(index){
      this.posRequestData.requestedPos = this.posRequestData.requestedPos.filter((pos, ind) => ind !== index)
    },
   async getPosRequest(){
      let data = {}
      data.id = this.$route.params.requestId
      await getPosRequestById(data).then(res =>{
        this.posRequested = res.data
        this.posRequestData = res.data
        if (res.data.requestedPos) {
          sessionStorage.setItem("totalPosRequest", JSON.stringify(res.data.requestedPos.length))
        }

      }).catch(err =>{
        console.log(err.response.data)
      })
    },
    async getAllProviders(){
      await getAllPosProvider().then(res =>{
        this.posProviders = res.data
      }).catch(err =>{
        console.log(err.response.data)
      })
    },
    formatDate(date){
      return dayjs(date).format('lll')
    },
  },
  async created() {
    await this.getPosRequest()
    await this.getAllProviders()
    let totalPos = sessionStorage.getItem("totalPosRequest")
    if (totalPos){
      this.totalPos = totalPos
    }
  }
}
</script>

<template>
  <div class="tw-flex tw-w-full tw-flex-col lg:">
    <div @click="routeBack" class="tw-cursor-pointer">
      <icons name="arrow-back"  />
    </div>
    <div class="tw-w-full tw-flex tw-justify-center lg:tw-mt-10 tw-mt-5 tw-p-5">
      <div class="company-dashboard tw-p-5">
         <h6 class="header">Approve Pos Request</h6>
         <div class="tw-flex tw-w-full tw-flex-col tw-mt-10">
           <h6 class="request-header tw-flex tw-items-center text-capitalize">
             <span class="tw-w-4/12">Requesting Company :</span> {{posRequested.transportCompanyName}}</h6>
           <h6 class="request-header tw-flex tw-items-center tw-mt-5">
             <span class="tw-w-4/12 ">Receiving Terminal : </span> {{posRequested.terminalName}}</h6>
           <h6 class="request-header tw-flex tw-items-center tw-mt-5" v-if="posRequested.requestedPos">
             <span class="tw-w-4/12 ">Total Pos Requested : </span> {{totalPos}}</h6>
           <h6 class="request-header tw-flex tw-items-center tw-mt-5" v-if="posRequested.requestedPos">
             <span class="tw-w-4/12 ">Total Pos Approved : </span> {{posRequested.totalPosApproved}}</h6>
           <h6 class="request-header tw-flex tw-items-center tw-mt-5">
             <span class="tw-w-4/12 ">Date of Request : </span> {{formatDate(posRequested.createdAt)}}</h6>


           <div class="tw-w-full tw-mt-10">
             <validation-observer ref="observer" v-slot="{ handleSubmit }">
               <form @submit.prevent="handleSubmit(approvePosRequest)">
                 <div class="tw-flex tw-w-full tw-flex-col" v-for="(request, index) in posRequestData.requestedPos" :key="index">
                   <div class="tw-w-full" v-if="request.approvalStatus === 'null' || request.approvalStatus === 'PENDING'">
                   <div class="tw-flex tw-w-full tw-items-center tw-justify-between tw-my-5" >
                     <h6 class="request-header tw-flex tw-items-center ">
                       Fill Pos Details &nbsp; <span v-if="index > 0"> ( {{index+1}} )</span></h6>
                     <div class="tw-flex tw-flex-row tw-items-center">
                       <div class="tw-w-auto" v-if="index > 0" >
                         <v-col  class="tw-cursor-pointer"
                                 @click="removeFromApproval(index)">
                           <icons   name="confirm-delete"  height="20" width="20" color="#004AAD"></icons>
                         </v-col>
                       </div>
                     </div>
                   </div>


                 <v-row class="tw-flex tw-flex-col lg:tw-flex-row tw-w-full tw-items-center">
                   <v-col class="tw-w-4/12">
                     <h6 class="request-header tw-flex tw-items-center">
                       Select POS Provider</h6>
                   </v-col>
                   <v-col class="tw-w-8/12">
                     <ValidationProvider name="POS Provider" rules="required" v-slot="{ classes, errors }">
                       <div class="tw-pt-4 mb-2" :class="classes">
                         <v-combobox :items="posProviders" item-text="companyName" item-value="id"  placeholder="Irofit"
                                     solo hide-details type="text" v-model="request.posProviderId"
                                     append-icon="mdi-chevron-down" ></v-combobox>
                         <span>{{ errors[0] }}</span>
                       </div>
                     </ValidationProvider>
                   </v-col>
                 </v-row>
                     <v-row class="tw-flex tw-flex-col lg:tw-flex-row tw-w-full tw-items-center">
                       <v-col class="tw-w-4/12">
                         <h6 class="request-header tw-flex tw-items-center">
                           POS Name:</h6>
                       </v-col>
                       <v-col class="tw-w-8/12">
                         <ValidationProvider name="POS Name" rules="required" v-slot="{ classes, errors }">
                           <div class="tw-pt-4 mb-2" :class="classes">
                             <v-text-field placeholder="A75" solo hide-details type="text" v-model="request.posName" required></v-text-field>
                             <span>{{ errors[0] }}</span>
                           </div>
                         </ValidationProvider>
                       </v-col>
                     </v-row>
                 <v-row class="tw-flex tw-flex-col lg:tw-flex-row tw-w-full tw-items-center">
                   <v-col class="tw-w-4/12">
                     <h6 class="request-header tw-flex tw-items-center">
                       Manufacturer:</h6>
                   </v-col>
                   <v-col class="tw-w-8/12">
                     <ValidationProvider name="Manufacturer" rules="required" v-slot="{ classes, errors }">
                       <div class="tw-pt-4 mb-2" :class="classes">
                         <v-text-field placeholder="Aisino" solo hide-details type="text" v-model="request.manufacturer" required></v-text-field>
                         <span>{{ errors[0] }}</span>
                       </div>
                     </ValidationProvider>
                   </v-col>
                 </v-row>

                 <v-row class="tw-flex tw-flex-col lg:tw-flex-row tw-w-full tw-items-center">
                   <v-col class="tw-w-4/12">
                     <h6 class="request-header tw-flex tw-items-center">
                       Serial Number:</h6>
                   </v-col>
                   <v-col class="tw-w-8/12">
                     <ValidationProvider name="Serial Number" rules="required" v-slot="{ classes, errors }">
                       <div class="tw-pt-4 mb-2" :class="classes">
                         <v-text-field placeholder="E7653JH5Q" solo hide-details type="text" v-model="request.serialNumber" required></v-text-field>
                         <span>{{ errors[0] }}</span>
                       </div>
                     </ValidationProvider>
                   </v-col>
                 </v-row>

                 <v-row class="tw-flex tw-flex-col lg:tw-flex-row tw-w-full tw-items-center">
                   <v-col class="tw-w-4/12">
                     <h6 class="request-header tw-flex tw-items-center">
                       Pos Type:</h6>
                   </v-col>
                   <v-col class="tw-w-8/12">
                     <ValidationProvider name="Serial Number" rules="required" v-slot="{ classes, errors }">
                       <div class="tw-pt-4 mb-2" :class="classes">
                         <v-combobox placeholder="Android" solo hide-details :items="posTypes"
                                     type="text" append-icon="mdi-chevron-down" v-model="request.type" required></v-combobox>
                         <span>{{ errors[0] }}</span>
                       </div>
                     </ValidationProvider>
                   </v-col>
                 </v-row>

                 <v-row class="tw-flex tw-flex-col lg:tw-flex-row tw-w-full tw-items-center">
                   <v-col class="tw-w-4/12">
                     <h6 class="request-header tw-flex tw-items-center">
                       Price:</h6>
                   </v-col>
                   <v-col class="tw-w-8/12">
                     <ValidationProvider name="Unit Price" rules="required" v-slot="{ classes, errors }">
                       <div class="tw-pt-4 mb-2" :class="classes">
                         <v-text-field placeholder="50000" solo hide-details type="Number" v-model="request.unitPrice" required></v-text-field>
                         <span>{{ errors[0] }}</span>
                       </div>
                     </ValidationProvider>
                   </v-col>
                 </v-row>
                     <v-row class="tw-flex tw-flex-col lg:tw-flex-row tw-w-full tw-items-center">
                       <v-col class="tw-w-4/12">
                         <h6 class="request-header tw-flex tw-items-center">
                           Management Fee:</h6>
                       </v-col>
                       <v-col class="tw-w-8/12">
                         <ValidationProvider name="Management Fee" rules="required" v-slot="{ classes, errors }">
                           <div class="tw-pt-4 mb-2" :class="classes">
                             <v-text-field placeholder="50000" solo hide-details type="Number" v-model="request.managementFee" required></v-text-field>
                             <span>{{ errors[0] }}</span>
                           </div>
                         </ValidationProvider>
                       </v-col>
                     </v-row>
                 </div>
                 </div>
                 <div class="tw-flex tw-w-full tw-justify-around tw-items-center tw-mt-5 action-btn-div">
                   <v-btn
                       text
                       class="first-btn"
                       @click="$emit('close')"
                       :style="{
                        color: '#004AAD',
                      border: '1px solid #004AAD' }">
                     Cancel
                   </v-btn>
                   <v-btn
                       text
                       class="second-btn"
                        type="submit"
                       :style="{ background: '#004AAD', color: 'white' }"
                       :loading="approveLoading">
                     Approve Request
                   </v-btn>
                 </div>
               </form>
             </validation-observer>

           </div>
         </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.company-dashboard{
  display: flex;
  background: #FFFFFF;
  width: 60%;
  min-height: 70vh;
  border-radius: 10px;
  flex-direction: column;
}
.header{
  font-family: 'Inter', sans-serif ;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  color: #004AAD;

}

.request-header {
  background: white;
  width: auto;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  display: flex;
  align-items: center;
  font-family: Inter, serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: #4f4f4f;
  flex-direction: row;
}

.action-btn-div {
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.01em;
  text-align: left;


  .first-btn {
    width: 120px;
    height: 32px;
    color: #004aad;
    border: 1px solid #004aad;
    box-sizing: border-box;
    border-radius: 6px;
    text-transform: capitalize!important;
  }

  .second-btn {
    width: max-content;
    height: 32px;
    background: #004aac;
    border-radius: 6px;
    color: white;
    margin-left: 20px;
    text-transform: capitalize!important;
  }
}
.col{
  flex-basis: auto !important;
}
</style>